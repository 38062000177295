import React from 'react'
import { Tag } from 'antd'
import { CustomEnumInfo } from '@antegma/accallio-cloud-hootsuite-adobe-workfront-api-javacript-sdk'

type TaskStatusComponentProps = {
  status?: string
  allStatuses: CustomEnumInfo[] | undefined
}

export const TaskStatusComponent = (props: TaskStatusComponentProps): JSX.Element => {
  const { status, allStatuses } = props

  let currentStatus
  if (allStatuses !== undefined) {
    currentStatus = allStatuses.find((s) => s.value === status)
  }

  const getTaskStatus = (): JSX.Element => {
    if (currentStatus !== undefined) {
      return <Tag color={`#${currentStatus?.color}`}>{currentStatus?.label}</Tag>
    }

    return <div />
  }

  return getTaskStatus()
}

export default TaskStatusComponent

import common from './common/reducers'
import overview from './overview/reducers'
import create from './create/reducers'
import taskDetails from './taskDetails/reducers'
import projectDetails from './projectDetails/reducers'

export default {
  common,
  overview,
  create,
  taskDetails,
  projectDetails,
}

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable dot-notation */
/* eslint-disable import/no-named-default */
import React, { useContext, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { PageHeader, Row, Col, Descriptions, Select, Slider, Avatar, Tooltip, Tabs, Tag, Button } from 'antd'
import { useRouteMatch } from 'react-router-dom'
import { DateTime } from 'luxon'
import { setActions } from '@antegma/accallio-cloud-react-components/lib/hootsuite/stream/redux/headeractions/actions'
import { TActionDefinition } from '@antegma/accallio-cloud-react-components/lib/hootsuite/stream/redux/headeractions/reducers'
import Loader from '@antegma/accallio-cloud-react-components/lib/app/components/Loader'
import { DocumentInfo, ProjectDetails } from '@antegma/accallio-cloud-hootsuite-adobe-workfront-api-javacript-sdk'
import { ApplicationContext } from '@antegma/accallio-cloud-react-components/lib/app/context'
import { UploadOutlined } from '@ant-design/icons'
import styles from './styles.module.scss'
import { ISagas } from '~/redux/interface'
import { getProject, updateProject } from '~/redux/projectDetails/actions'
import { openComposer } from '~/redux/common/actions'
import { default as historyAction } from '~/redux/history/actions'
import UserComponent from '~/components/user'
import TaskStatusComponent from '~/components/taskstatus'
import UpdateComponent from '~/components/update'

const { TabPane } = Tabs

const ProjectDetailsView: React.FunctionComponent = () => {
  const route = useRouteMatch<{ projectId: string }>()
  const ctx = useContext(ApplicationContext)
  const { Option } = Select

  const loading = useSelector((state: ISagas) => {
    return state.projectDetails.loading
  })
  const projectDetailsState = useSelector((state: ISagas) => {
    return state.projectDetails
  })

  let hootsuiteUserId: string
  if (ctx.extraQueryParams !== undefined) {
    hootsuiteUserId = ctx.extraQueryParams.hootsuiteUserId
  }

  const dispatch = useDispatch()

  const loadInfo = (): void => {
    dispatch(getProject(projectId))
  }

  const {
    params: { projectId },
  } = route

  useEffect(() => {
    loadInfo()
    const mainAction: TActionDefinition = {
      label: '< Back',
      action: historyAction.GO_BACK,
    }

    const subActions: TActionDefinition[] = []
    dispatch(setActions(mainAction, subActions))
  }, [])

  const handleChangeStatus = (value): void => {
    const project: ProjectDetails = { status: value }
    dispatch(updateProject(projectId, project))
  }

  const handleChangePriority = (value): void => {
    const project: ProjectDetails = { priority: value }
    dispatch(updateProject(projectId, project))
  }

  const handleChangePercentComplete = (value): void => {
    const project: ProjectDetails = { percentComplete: value }
    dispatch(updateProject(projectId, project))
  }

  const handleOpenComposer = (document: DocumentInfo): void => {
    dispatch(openComposer(document, hootsuiteUserId))
  }

  return loading !== true ? (
    <PageHeader title={projectDetailsState.project.name}>
      <Tag color="geekblue">Project</Tag>
      <Row gutter={[16, 16]} className={styles.projectDetails}>
        <Col xs={24}>
          <Tabs defaultActiveKey="projectDetails">
            <TabPane tab="Details" key="projectDetails">
              <Descriptions style={{ width: '100%' }} column={1} layout="vertical" colon={false}>
                <Descriptions.Item label="Description">{projectDetailsState.project.description}</Descriptions.Item>
                <Descriptions.Item label="Status">
                  <Select defaultValue={projectDetailsState.project.status} style={{ width: 200 }}>
                    {projectDetailsState.project !== undefined &&
                      projectDetailsState.project.allStatuses !== undefined &&
                      projectDetailsState.project.allStatuses?.map((s, index) => (
                        <Option key={s.value} value={s.value} label={s.label}>
                          <div className={styles.statusLabel}>
                            <div className={styles.statusIndicator} style={{ backgroundColor: `#${s.color}` }} />
                            {s.label}
                          </div>
                        </Option>
                      ))}
                  </Select>
                </Descriptions.Item>
                <Descriptions.Item label="Priority">
                  <Select defaultValue={projectDetailsState.project.priority?.toString()} style={{ width: 200 }} onChange={handleChangePriority}>
                    {projectDetailsState.project !== undefined &&
                      projectDetailsState.project.allPriorities !== undefined &&
                      projectDetailsState.project.allPriorities?.map((s, index) => (
                        <Option key={s.value} value={s.value} label={s.label}>
                          <div className={styles.statusLabel}>
                            <div className={styles.statusIndicator} style={{ backgroundColor: `#${s.color}` }} />
                            {s.label}
                          </div>
                        </Option>
                      ))}
                  </Select>
                </Descriptions.Item>
                <Descriptions.Item label="Condition">
                  <TaskStatusComponent status={projectDetailsState.project.condition} allStatuses={projectDetailsState.project.allConditions} />
                </Descriptions.Item>
                <Descriptions.Item label="Progress">
                  <Row style={{ width: '100%' }}>
                    <Col span={18}>
                      <Slider
                        min={0}
                        max={100}
                        defaultValue={
                          projectDetailsState.project !== undefined && projectDetailsState.project.percentComplete !== undefined
                            ? projectDetailsState.project.percentComplete
                            : 0
                        }
                      />
                    </Col>
                    <Col span={6}>
                      {projectDetailsState.project !== undefined && projectDetailsState.project.percentComplete !== undefined
                        ? projectDetailsState.project.percentComplete
                        : 0}{' '}
                      %
                    </Col>
                  </Row>
                </Descriptions.Item>

                <Descriptions.Item label="Planned Completion Date">
                  {projectDetailsState.project.plannedCompletionDate !== undefined &&
                    new Intl.DateTimeFormat('en-US', {
                      month: 'numeric',
                      day: 'numeric',
                      year: 'numeric',
                    }).format(DateTime.fromISO(projectDetailsState.project.plannedCompletionDate))}
                </Descriptions.Item>

                <Descriptions.Item label="Owner">
                  <UserComponent user={projectDetailsState.project.owner} />
                </Descriptions.Item>
              </Descriptions>
            </TabPane>
            <TabPane tab="Docs" key="projectDocs">
              {projectDetailsState.project.documents !== undefined &&
                projectDetailsState.project.documents.map((document, index) => {
                  return (
                    <Row key={document.id} className={styles.document}>
                      <Col span={24}>
                        <div>
                          <div style={{ width: '110px', float: 'left' }}>
                            <Tooltip title={document.name}>
                              <div style={{ width: '100px', height: '100px' }}>
                                <img style={{ objectFit: 'cover' }} src={document.previewURL} width={100} height={100} alt={document.name} />
                              </div>
                            </Tooltip>
                          </div>
                          <div style={{ float: 'left' }}>
                            <div>{document.name}</div>
                            <div style={{ marginTop: '7px' }}>
                              <Button icon={<UploadOutlined />} onClick={(e): void => handleOpenComposer(document)}>
                                Upload to Hootsuite
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )
                })}
            </TabPane>
            <TabPane tab="Updates" key="projectUpdates">
              {projectDetailsState.project.updates !== undefined &&
                projectDetailsState.project.updates.map((update, index) => {
                  return <UpdateComponent key={update.id} update={update} />
                })}
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </PageHeader>
  ) : (
    <Loader />
  )
}

export default {
  name: 'Project',
  routeProps: {
    path: '/projects/:projectId',
    component: ProjectDetailsView,
  },
  navProps: {
    title: 'Project ',
  },
}

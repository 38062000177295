import { ProjectDetails } from '@antegma/accallio-cloud-hootsuite-adobe-workfront-api-javacript-sdk'
import { AnyAction } from 'redux'
import actions from './actions'

export type TProjectDetailsState = {
  project: ProjectDetails
  loading: boolean
}
const initialState = {
  project: {},
  loading: false,
  assignmentInfos: [],
}
export default function projectDetailsReducer(state: TProjectDetailsState = initialState, action: AnyAction): TProjectDetailsState {
  switch (action.type) {
    case actions.SET_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case actions.GET_STATE:
      return state
    default:
      return state
  }
}

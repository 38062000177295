import { TaskDetails, ProjectDetails, OpTaskDetails, UserInfo } from '@antegma/accallio-cloud-hootsuite-adobe-workfront-api-javacript-sdk'
import { AnyAction } from 'redux'
import actions from './actions'

export type TCreateState = {
  task?: TaskDetails
  opTask?: OpTaskDetails
  projects: ProjectDetails[]
  type: string
  loading: boolean
  selectedProjectID?: string
  projectTaskEnabled: boolean
  users: UserInfo[]
  assignedToID?: string
  plannedCompletionDate?: Date
  messageName?: string
  messageDescription?: string
}
const initialState = {
  task: {},
  projects: [],
  type: 'task',
  loading: false,
  selectedProjectID: undefined,
  projectTaskEnabled: false,
  users: [],
  assignedToID: undefined,
  messageName: '',
  messageDescription: '',
}
export default function createTaskReducer(state: TCreateState = initialState, action: AnyAction): TCreateState {
  switch (action.type) {
    case actions.SET_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case actions.GET_STATE:
      return state
    default:
      return state
  }
}

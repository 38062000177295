import { TeamRequestList, TaskDetails } from '@antegma/accallio-cloud-hootsuite-adobe-workfront-api-javacript-sdk'
import { AnyAction } from 'redux'
import actions from './actions'

export type TOverviewState = {
  tasks: TaskDetails[]
  count: number
  page: number
  pageSize: number
  total: number
  loading: boolean
  teamRequests: TeamRequestList[]
}
const initialState = {
  tasks: [],
  count: 0,
  loading: false,
  page: 0,
  pageSize: 10,
  total: 0,
  query: '',
  teamRequests: [],
}
export default function overviewReducer(state: TOverviewState = initialState, action: AnyAction): TOverviewState {
  switch (action.type) {
    case actions.SET_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case actions.GET_STATE:
      return state
    default:
      return state
  }
}

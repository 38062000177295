/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { DocumentInfo, TaskDetails } from '@antegma/accallio-cloud-hootsuite-adobe-workfront-api-javacript-sdk'
import { AnyAction } from 'redux'

const actions = {
  GET_TASK: 'TASK_DETAILS/GET_TASK',
  SET_STATE: 'TASK_DETAILS/SET_STATE',
  LOAD_STATE: 'TASK_DETAILS/LOAD_STATE',
  GET_STATE: 'TASK_DETAILS/GET_STATE',
  MARK_DONE: 'TASK_DETAILS/MARK_DONE',
  MARK_NOT_DONE: 'TASK_DETAILS/MARK_NOT_DONE',
  MARK_DONE_WITH_MY_PART: 'TASK_DETAILS/MARK_DONE_WITH_MY_PART',
  WORK_ON_IT: 'TASK_DETAILS/WORK_ON_IT',
  UPDATE_TASK: 'TASK_DETAILS/UPDATE_TASK',
}
export default actions

export const setLoading = (loading: boolean): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload: {
      loading,
    },
  }
}

export const getTask = (taskId: string): AnyAction => {
  return {
    type: actions.GET_TASK,
    payload: {
      taskId,
    },
  }
}

export const updateTask = (taskId: string, task: TaskDetails): AnyAction => {
  return {
    type: actions.UPDATE_TASK,
    payload: {
      taskId,
      task,
    },
  }
}

export const setState = (payload: any): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload,
  }
}

export const markDone = (taskId: string, assignmentId: string): AnyAction => {
  return {
    type: actions.MARK_DONE,
    payload: {
      taskId,
      assignmentId,
    },
  }
}

export const markNotDone = (taskId: string, assignmentId: string): AnyAction => {
  return {
    type: actions.MARK_NOT_DONE,
    payload: {
      taskId,
      assignmentId,
    },
  }
}

export const markDoneWithMyPart = (taskId: string, assignmentId: string): AnyAction => {
  return {
    type: actions.MARK_DONE_WITH_MY_PART,
    payload: {
      taskId,
      assignmentId,
    },
  }
}

export const workOnIt = (taskId: string, assignmentId: string): AnyAction => {
  return {
    type: actions.WORK_ON_IT,
    payload: {
      taskId,
      assignmentId,
    },
  }
}

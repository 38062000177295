/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable camelcase */
import { all, put, takeEvery, select } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import Axios from 'axios'
import { TDefaultSagas } from '@antegma/accallio-cloud-react-components/lib/hootsuite/common/redux/interface'
// import { ISagas } from '~/redux/interface'
import projectApi from '~/services/Project'
import actions, { setLoading } from './actions'

function* LOAD_STATE() {
  yield put(setLoading(true))

  yield put(setLoading(false))
}

function* GET_PROJECT({ payload }: AnyAction) {
  // const ctx = useContext(ApplicationContext)
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))

  const { projectId } = payload

  yield put(setLoading(true))

  const options = { headers: { Authorization: `Bearer ${accessToken}` } }

  const projectResponse = yield projectApi.getProject(projectId, options)
  const project = projectResponse.data

  yield put({
    type: actions.SET_STATE,
    payload: {
      project,
      loading: false,
    },
  })
}

function* UPDATE_PROJECT({ payload }: AnyAction) {
  // const ctx = useContext(ApplicationContext)
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))

  const { projectId, project } = payload

  const options = { headers: { Authorization: `Bearer ${accessToken}` } }

  const projectResponse = yield projectApi.updateProject(projectId, project, options)
  const updatedProject = projectResponse.data

  yield put({
    type: actions.SET_STATE,
    payload: {
      project: updatedProject,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_STATE, LOAD_STATE),
    takeEvery(actions.GET_PROJECT, GET_PROJECT),
    takeEvery(actions.UPDATE_PROJECT, UPDATE_PROJECT),
  ])
}

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable camelcase */
import { all, put, takeEvery, select, call, AllEffect } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { AnyAction } from 'redux'
import Axios, { AxiosResponse } from 'axios'
import { TDefaultSagas } from '@antegma/accallio-cloud-react-components/lib/hootsuite/common/redux/interface'
import { TaskDetailsList, TeamRequestList } from '@antegma/accallio-cloud-hootsuite-adobe-workfront-api-javacript-sdk'
// import { ISagas } from '~/redux/interface'
import taskApi from '~/services/Task'
import teamRequestApi from '~/services/TeamRequest'
import actions, { setLoading } from './actions'

function* LOAD_STATE() {
  yield put(setLoading(true))

  yield put(setLoading(false))
}

function* CREATE_TASK({ payload }: AnyAction) {
  yield put(push('/create'))
}

function* GET_TASKS({ payload }: AnyAction) {
  // const ctx = useContext(ApplicationContext)
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))

  yield put(setLoading(true))

  const options = { headers: { Authorization: `Bearer ${accessToken}` } }
  const result: AxiosResponse<TaskDetailsList> = yield taskApi.getTasks(options)

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
      tasks: result.data.data,
    },
  })
}

function* GET_TEAM_REQUESTS({ payload }: AnyAction) {
  // const ctx = useContext(ApplicationContext)
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))

  yield put(setLoading(true))

  const options = { headers: { Authorization: `Bearer ${accessToken}` } }
  const result: AxiosResponse<TeamRequestList[]> = yield teamRequestApi.getTeamRequests(options)

  yield put({
    type: actions.SET_STATE,
    payload: {
      teamRequests: result.data,
    },
  })

  /* const uniqueUsers = result.data.data?.map((task) => task.assignedToID)

  if (uniqueUsers !== undefined) {
    const userResponses: AxiosResponse<User>[] = yield all([
      ...uniqueUsers.map((uniqueUserId) => {
        return uniqueUserId !== undefined ? userApi.getUser(uniqueUserId, options) : undefined
      }),
    ])
    const users: User[] = userResponses.map((userResponse) => {
      return userResponse.data
    })

    yield put({
      type: actions.SET_USERS,
      payload: {
        users,
      },
    })
  } */

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
}

function* GET_TASKS_COUNT({ payload }: AnyAction) {
  // const ctx = useContext(ApplicationContext)
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))

  yield put(setLoading(true))

  const options = { headers: { Authorization: `Bearer ${accessToken}` } }
  const result = yield taskApi.getTasksCount(options)

  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
      count: result.data.count,
    },
  })
}

function* MARK_DONE_TASK({ payload }: AnyAction) {
  // const ctx = useContext(ApplicationContext)
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))

  const { taskId } = payload

  const options = { headers: { Authorization: `Bearer ${accessToken}` } }

  const status = 'DN'

  yield taskApi.markDone(taskId, status, options)

  const taskResponse = yield taskApi.getTasks(options)
  const tasks = taskResponse.data

  yield put({
    type: actions.SET_STATE,
    payload: {
      tasks,
    },
  })
}

function* MARK_NOT_DONE_TASK({ payload }: AnyAction) {
  // const ctx = useContext(ApplicationContext)
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))

  const { taskId, assignmentId } = payload

  const options = { headers: { Authorization: `Bearer ${accessToken}` } }

  yield taskApi.markNotDone(taskId, assignmentId, options)

  const taskResponse = yield taskApi.getTasks(options)
  const tasks = taskResponse.data

  yield put({
    type: actions.SET_STATE,
    payload: {
      tasks,
    },
  })
}

function* MARK_DONE_WITH_MY_PART_TASK({ payload }: AnyAction) {
  // const ctx = useContext(ApplicationContext)
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))

  const { taskId, assignmentId } = payload

  const options = { headers: { Authorization: `Bearer ${accessToken}` } }

  yield taskApi.markDoneWithMyPart(taskId, assignmentId, options)

  const taskResponse = yield taskApi.getTasks(options)
  const tasks = taskResponse.data

  yield put({
    type: actions.SET_STATE,
    payload: {
      tasks,
    },
  })
}

function* WORK_ON_IT_TASK({ payload }: AnyAction) {
  // const ctx = useContext(ApplicationContext)
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))

  const { taskId, assignmentId } = payload

  const options = { headers: { Authorization: `Bearer ${accessToken}` } }

  yield taskApi.workOnIt(taskId, assignmentId, options)

  const taskResponse = yield taskApi.getTasks(options)
  const tasks = taskResponse.data

  yield put({
    type: actions.SET_STATE,
    payload: {
      tasks,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_STATE, LOAD_STATE),
    takeEvery(actions.CREATE_TASK, CREATE_TASK),
    takeEvery(actions.GET_TASKS, GET_TASKS),
    takeEvery(actions.GET_TASKS_COUNT, GET_TASKS_COUNT),
    takeEvery(actions.GET_TEAM_REQUESTS, GET_TEAM_REQUESTS),
    takeEvery(actions.MARK_DONE_TASK, MARK_DONE_TASK),
    takeEvery(actions.MARK_DONE_WITH_MY_PART_TASK, MARK_DONE_WITH_MY_PART_TASK),
    takeEvery(actions.MARK_NOT_DONE_TASK, MARK_NOT_DONE_TASK),
    takeEvery(actions.WORK_ON_IT_TASK, WORK_ON_IT_TASK),
  ])
}

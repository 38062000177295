/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ProjectDetails } from '@antegma/accallio-cloud-hootsuite-adobe-workfront-api-javacript-sdk'
import { AnyAction } from 'redux'

const actions = {
  GET_PROJECT: 'PROJECT_DETAILS/GET_PROJECT',
  UPDATE_PROJECT: 'PROJECT_DETAILS/UPDATE_PROJECT',
  SET_STATE: 'PROJECT_DETAILS/SET_STATE',
  LOAD_STATE: 'PROJECT_DETAILS/LOAD_STATE',
  GET_STATE: 'PROJECT_DETAILS/GET_STATE',
}
export default actions

export const setLoading = (loading: boolean): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload: {
      loading,
    },
  }
}

export const getProject = (projectId: string): AnyAction => {
  return {
    type: actions.GET_PROJECT,
    payload: {
      projectId,
    },
  }
}

export const updateProject = (projectId: string, project: ProjectDetails): AnyAction => {
  return {
    type: actions.UPDATE_PROJECT,
    payload: {
      projectId,
      project,
    },
  }
}

export const setState = (payload: any): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload,
  }
}

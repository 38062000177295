/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable dot-notation */
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { ClockCircleOutlined, MoreOutlined } from '@ant-design/icons'
import { Layout, Input, Collapse, Badge, Card, Dropdown, Menu } from 'antd'
import { DateTime } from 'luxon'
import { createSelector } from 'reselect'
import memoize from 'lodash.memoize'
import { setActions } from '@antegma/accallio-cloud-react-components/lib/hootsuite/stream/redux/headeractions/actions'
import { TActionDefinition } from '@antegma/accallio-cloud-react-components/lib/hootsuite/stream/redux/headeractions/reducers'
import { AssignmentInfo } from '@antegma/accallio-cloud-hootsuite-adobe-workfront-api-javacript-sdk'
import { ISagas } from '~/redux/interface'
import actions, { getTasks, getTeamRequests, markDoneTask, markDoneWithMyPartTask, workOnItTask } from '~/redux/overview/actions'
import { TOverviewState } from '~/redux/overview/reducers'
import style from './index.module.scss'
import { ProjectComponent } from '~/components/project'
import { TaskStatusComponent } from '~/components/taskstatus'
import AssignmentInfoComponent from '~/components/assignmentinfo'

const { Footer } = Layout
const { Search } = Input

const Overview: React.FunctionComponent = () => {
  const commonState = useSelector((state: ISagas) => {
    return state.common
  })
  const overviewState = useSelector((state: ISagas) => {
    return state.overview
  })

  const dispatch = useDispatch()
  const { Panel } = Collapse
  const { Meta } = Card

  const loadInfo = (): void => {
    console.log('loadinfo triggered')
    dispatch(getTasks())
    dispatch(getTeamRequests())
  }

  useEffect(() => {
    loadInfo()
    const mainAction: TActionDefinition = {
      label: 'Create Task',
      action: actions.CREATE_TASK,
    }

    const subActions: TActionDefinition[] = []
    dispatch(setActions(mainAction, subActions))
  }, [])

  const callback = (key): void => {
    console.log(key)
  }

  const handleRefreshRequest = (data): void => {
    loadInfo()
    console.log('Refresh')
  }

  const handleDoneWithMyPart = (taskId?: string, currentAssignment?: AssignmentInfo): void => {
    if (currentAssignment !== undefined && currentAssignment.id !== undefined && taskId !== undefined) {
      dispatch(markDoneWithMyPartTask(taskId, currentAssignment?.id))
    }
  }

  const handleMarkDone = (taskId?: string, currentAssignment?: AssignmentInfo): void => {
    if (currentAssignment !== undefined && currentAssignment.id !== undefined && taskId !== undefined) {
      dispatch(markDoneTask(taskId, currentAssignment?.id))
    }
  }

  const handleWorkOnIt = (taskId?: string, currentAssignment?: AssignmentInfo): void => {
    if (currentAssignment !== undefined && currentAssignment.id !== undefined && taskId !== undefined) {
      dispatch(workOnItTask(taskId, currentAssignment?.id))
    }
  }

  const menu = (taskId?: string, assignments?: AssignmentInfo[]): JSX.Element => {
    const currentAssignment = assignments?.find((s) => s.assignedTo !== undefined && s.assignedTo?.id === commonState.currentUser.id)

    return (
      <Menu>
        {currentAssignment !== undefined && currentAssignment?.status === 'AA' && (
          <Menu.Item
            onClick={(): void => {
              handleWorkOnIt(taskId, currentAssignment)
            }}
          >
            Work on it
          </Menu.Item>
        )}
        {currentAssignment !== undefined && currentAssignment?.status === 'AD' && (
          <Menu.Item
            onClick={(): void => {
              handleDoneWithMyPart(taskId, currentAssignment)
            }}
          >
            Done with my part
          </Menu.Item>
        )}
        {currentAssignment !== undefined && currentAssignment?.status === 'AD' && (
          <Menu.Item
            onClick={(): void => {
              handleMarkDone(taskId, currentAssignment)
            }}
          >
            Complete
          </Menu.Item>
        )}
      </Menu>
    )
  }

  window['hsp']['bind']('refresh', handleRefreshRequest)

  const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.`

  return (
    <Collapse defaultActiveKey={['tasks']} onChange={callback}>
      <Panel className={style.panel} header="Tasks" key="tasks" extra={<Badge count={overviewState.tasks.length} />}>
        {overviewState.tasks !== undefined &&
          overviewState.tasks?.map((task, index) => (
            <div key={task.id} className={style.element}>
              <div>
                <div style={{ float: 'left' }}>
                  <ProjectComponent project={task.project} />
                  <div>
                    <Link to={`/tasks/${task.id}`}>
                      <b>{task.name}</b>
                    </Link>
                  </div>
                </div>
                <div style={{ float: 'right', textAlign: 'right' }}>
                  <div>
                    <ClockCircleOutlined style={{ marginRight: 5 }} />
                    {task.plannedCompletionDate !== undefined &&
                      new Intl.DateTimeFormat('en-US', {
                        month: 'short',
                        day: 'numeric',
                      }).format(DateTime.fromISO(task.plannedCompletionDate))}
                  </div>
                  <div>
                    <AssignmentInfoComponent assignments={task.assignments} />
                  </div>
                </div>
                <div style={{ clear: 'both' }} />
              </div>
              <div>
                <div style={{ float: 'left' }}>
                  <TaskStatusComponent status={task.status} allStatuses={task.allStatuses} />
                </div>
                <div style={{ float: 'right', textAlign: 'right' }}>
                  <Dropdown overlay={menu(task.id, task.assignments)} placement="bottomLeft">
                    <MoreOutlined rotate={90} style={{ fontSize: '150%' }} />
                  </Dropdown>
                </div>
                <div style={{ clear: 'both' }} />
              </div>
            </div>
          ))}
      </Panel>
      {overviewState.teamRequests?.map((team, index) => (
        <Panel className={style.panel} header={team.teamName} key="aa" extra={<Badge count={team.count} />}>
          {team.data?.map((issue, issueIndex) => (
            <div key={issue.id} className={style.element}>
              <div>
                <div style={{ float: 'left' }}>
                  Placeholder
                  <div>
                    <b>{issue.name}</b>
                  </div>
                </div>
                <div style={{ float: 'right', textAlign: 'right' }}>Placeholder</div>
                <div style={{ clear: 'both' }} />
              </div>
              <div>
                <div style={{ float: 'left' }}>
                  <TaskStatusComponent status={issue.status} allStatuses={issue.allStatuses} />
                </div>
                <div style={{ float: 'right', textAlign: 'right' }}>
                  <Dropdown overlay={menu} placement="bottomLeft">
                    <MoreOutlined rotate={90} style={{ fontSize: '150%' }} />
                  </Dropdown>
                </div>
                <div style={{ clear: 'both' }} />
              </div>
            </div>
          ))}
        </Panel>
      ))}
    </Collapse>
  )
}

export default {
  name: 'Overview',
  routeProps: {
    path: '/overview',
    component: Overview,
  },
  navProps: {
    title: 'Overview ',
  },
}

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable camelcase */
import { all, takeEvery } from 'redux-saga/effects'
import { useHistory } from 'react-router'
import actions from './actions'

function* GO_BACK() {
  yield window.history.go(-1)
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GO_BACK, GO_BACK)])
}

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AnyAction } from 'redux'
import { DocumentInfo } from '@antegma/accallio-cloud-hootsuite-adobe-workfront-api-javacript-sdk'

const actions = {
  SET_STATE: 'COMMON/SET_STATE',
  LOAD_STATE: 'COMMON/LOAD_STATE',
  GET_STATE: 'COMMON/GET_STATE',
  OPEN_COMPOSER: 'TASK_DETAILS/OPEN_COMPOSER',
}
export default actions

export const setLoading = (loading: boolean): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload: {
      loading,
    },
  }
}

export const openComposer = (document: DocumentInfo, hootsuiteUserId: string): AnyAction => {
  return {
    type: actions.OPEN_COMPOSER,
    payload: {
      document,
      hootsuiteUserId,
    },
  }
}

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable camelcase */
/* eslint-disable dot-notation */
import { all, put, takeEvery, select } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import Axios from 'axios'
import { TDefaultSagas } from '@antegma/accallio-cloud-react-components/lib/hootsuite/common/redux/interface'
// import { ISagas } from '~/redux/interface'
import taskApi from '~/services/Task'
import actions, { setLoading } from './actions'

function* LOAD_STATE() {
  yield put(setLoading(true))

  yield put(setLoading(false))
}

function* GET_TASK({ payload }: AnyAction) {
  // const ctx = useContext(ApplicationContext)
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))

  const { taskId } = payload

  yield put(setLoading(true))

  const options = { headers: { Authorization: `Bearer ${accessToken}` } }

  const taskResponse = yield taskApi.getTask(taskId, options)
  const task = taskResponse.data

  yield put({
    type: actions.SET_STATE,
    payload: {
      task,
      loading: false,
    },
  })
}

function* UPDATE_TASK({ payload }: AnyAction) {
  // const ctx = useContext(ApplicationContext)
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))

  const { taskId, task } = payload

  const options = { headers: { Authorization: `Bearer ${accessToken}` } }

  const taskResponse = yield taskApi.updateTask(taskId, task, options)
  const updatedTask = taskResponse.data

  yield put({
    type: actions.SET_STATE,
    payload: {
      task: updatedTask,
    },
  })
}

function* MARK_DONE({ payload }: AnyAction) {
  // const ctx = useContext(ApplicationContext)
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))

  const { taskId } = payload

  const options = { headers: { Authorization: `Bearer ${accessToken}` } }

  const status = 'DN'

  yield taskApi.markDone(taskId, status, options)

  const taskResponse = yield taskApi.getTask(taskId, options)
  const updatedTask = taskResponse.data

  yield put({
    type: actions.SET_STATE,
    payload: {
      task: updatedTask,
    },
  })
}

function* MARK_NOT_DONE({ payload }: AnyAction) {
  // const ctx = useContext(ApplicationContext)
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))

  const { taskId, assignmentId } = payload

  const options = { headers: { Authorization: `Bearer ${accessToken}` } }

  yield taskApi.markNotDone(taskId, assignmentId, options)

  const taskResponse = yield taskApi.getTask(taskId, options)
  const updatedTask = taskResponse.data

  yield put({
    type: actions.SET_STATE,
    payload: {
      task: updatedTask,
    },
  })
}

function* MARK_DONE_WITH_MY_PART({ payload }: AnyAction) {
  // const ctx = useContext(ApplicationContext)
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))

  const { taskId, assignmentId } = payload

  const options = { headers: { Authorization: `Bearer ${accessToken}` } }

  yield taskApi.markDoneWithMyPart(taskId, assignmentId, options)

  const taskResponse = yield taskApi.getTask(taskId, options)
  const updatedTask = taskResponse.data

  yield put({
    type: actions.SET_STATE,
    payload: {
      task: updatedTask,
    },
  })
}

function* WORK_ON_IT({ payload }: AnyAction) {
  // const ctx = useContext(ApplicationContext)
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))

  const { taskId, assignmentId } = payload

  const options = { headers: { Authorization: `Bearer ${accessToken}` } }

  yield taskApi.workOnIt(taskId, assignmentId, options)

  const taskResponse = yield taskApi.getTask(taskId, options)
  const updatedTask = taskResponse.data

  yield put({
    type: actions.SET_STATE,
    payload: {
      task: updatedTask,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_STATE, LOAD_STATE),
    takeEvery(actions.GET_TASK, GET_TASK),
    takeEvery(actions.UPDATE_TASK, UPDATE_TASK),
    takeEvery(actions.MARK_DONE, MARK_DONE),
    takeEvery(actions.MARK_NOT_DONE, MARK_NOT_DONE),
    takeEvery(actions.MARK_DONE_WITH_MY_PART, MARK_DONE_WITH_MY_PART),
    takeEvery(actions.WORK_ON_IT, WORK_ON_IT),
  ])
}

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable dot-notation */
/* eslint-disable import/no-named-default */
import React, { useEffect, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationContext } from '@antegma/accallio-cloud-react-components/lib/app/context'
import { CheckCircleFilled, TeamOutlined, ToolOutlined, UploadOutlined } from '@ant-design/icons'
import { PageHeader, Button, Row, Col, Descriptions, Select, Slider, Avatar, Tooltip, Tabs, Tag, Dropdown, Menu, Image } from 'antd'
import { Link, useRouteMatch } from 'react-router-dom'
import { DateTime } from 'luxon'
import { setActions } from '@antegma/accallio-cloud-react-components/lib/hootsuite/stream/redux/headeractions/actions'
import { TActionDefinition } from '@antegma/accallio-cloud-react-components/lib/hootsuite/stream/redux/headeractions/reducers'
import Loader from '@antegma/accallio-cloud-react-components/lib/app/components/Loader'
import { DocumentInfo, TaskDetails } from '@antegma/accallio-cloud-hootsuite-adobe-workfront-api-javacript-sdk'
import styles from './styles.module.scss'
import { ISagas } from '~/redux/interface'
import { getTask, updateTask, markDone, markNotDone, markDoneWithMyPart, workOnIt } from '~/redux/taskDetails/actions'
import { openComposer } from '~/redux/common/actions'
import { default as historyAction } from '~/redux/history/actions'
import { ProjectComponent } from '~/components/project'
import UserComponent from '~/components/user'
import UpdateComponent from '~/components/update'

const { TabPane } = Tabs
const { Option } = Select

const TaskDetailsView: React.FunctionComponent = () => {
  const route = useRouteMatch<{ taskId: string }>()
  const ctx = useContext(ApplicationContext)

  const loading = useSelector((state: ISagas) => {
    return state.taskDetails.loading
  })
  const commonState = useSelector((state: ISagas) => {
    return state.common
  })
  const taskDetailsState = useSelector((state: ISagas) => {
    return state.taskDetails
  })

  let hootsuiteUserId: string
  if (ctx.extraQueryParams !== undefined) {
    hootsuiteUserId = ctx.extraQueryParams.hootsuiteUserId
  }

  const currentAssignment = taskDetailsState.task.assignments?.find(
    (s) => s.assignedTo !== undefined && s.assignedTo?.id === commonState.currentUser.id,
  )

  const dispatch = useDispatch()

  const loadInfo = (): void => {
    dispatch(getTask(taskId))
  }

  const {
    params: { taskId },
  } = route

  useEffect(() => {
    loadInfo()
    const mainAction: TActionDefinition = {
      label: '< Back',
      action: historyAction.GO_BACK,
    }

    const subActions: TActionDefinition[] = []
    dispatch(setActions(mainAction, subActions))
  }, [])

  const handleChangeStatus = (value): void => {
    const task: TaskDetails = { status: value }
    dispatch(updateTask(taskId, task))
  }

  const handleChangePriority = (value): void => {
    const task: TaskDetails = { priority: value }
    dispatch(updateTask(taskId, task))
  }

  const handleChangeCondition = (value): void => {
    const task: TaskDetails = { condition: value }
    dispatch(updateTask(taskId, task))
  }

  const handleChangePercentComplete = (value): void => {
    const task: TaskDetails = { percentComplete: value }
    dispatch(updateTask(taskId, task))
  }

  const handleDoneWithMyPart = (e): void => {
    if (currentAssignment !== undefined && currentAssignment.id !== undefined) {
      dispatch(markDoneWithMyPart(taskId, currentAssignment?.id))
    }
  }

  const handleMarkDone = (e): void => {
    if (currentAssignment !== undefined && currentAssignment.id !== undefined) {
      dispatch(markDone(taskId, currentAssignment?.id))
    }
  }

  const handleWorkOnIt = (e): void => {
    if (currentAssignment !== undefined && currentAssignment.id !== undefined) {
      dispatch(workOnIt(taskId, currentAssignment?.id))
    }
  }

  const handleNotDone = (e): void => {
    if (currentAssignment !== undefined && currentAssignment.id !== undefined) {
      dispatch(markNotDone(taskId, currentAssignment?.id))
    }
  }

  const handleOpenComposer = (document: DocumentInfo): void => {
    dispatch(openComposer(document, hootsuiteUserId))
  }

  const menu = (): JSX.Element => {
    return (
      <Menu>{currentAssignment !== undefined && currentAssignment?.status === 'AD' && <Menu.Item onClick={handleMarkDone}>Complete</Menu.Item>}</Menu>
    )
  }

  return loading !== true ? (
    <PageHeader title={taskDetailsState.task.name}>
      <Tag color="geekblue">Task</Tag>
      <Row gutter={[16, 16]} className={styles.taskDetails}>
        <Col xs={24}>
          <Tabs defaultActiveKey="taskDetails">
            <TabPane tab="Details" key="taskDetails">
              <Row gutter={[16, 16]}>
                <Col xs={24}>
                  {currentAssignment !== undefined && currentAssignment.status === 'AD' && (
                    <Dropdown.Button onClick={handleDoneWithMyPart} overlay={menu} type="primary" style={{ marginTop: 10, marginBottom: 10 }}>
                      Done with my part
                    </Dropdown.Button>
                  )}
                  {currentAssignment !== undefined && currentAssignment.status === 'AA' && (
                    <Button onClick={handleWorkOnIt} type="primary" style={{ marginTop: 10, marginBottom: 10 }}>
                      Work on IT
                    </Button>
                  )}
                  {currentAssignment !== undefined && currentAssignment.status === 'DN' && (
                    <Button onClick={handleNotDone} type="primary" style={{ marginTop: 10, marginBottom: 10 }}>
                      Not Done
                    </Button>
                  )}
                </Col>
              </Row>
              <Descriptions style={{ width: '100%' }} column={1} layout="vertical" colon={false}>
                <Descriptions.Item label="Description">{taskDetailsState.task.description}</Descriptions.Item>
                <Descriptions.Item label="Status">
                  <Select defaultValue={taskDetailsState.task.status} style={{ width: 200 }} onChange={handleChangeStatus}>
                    {taskDetailsState.task !== undefined &&
                      taskDetailsState.task.allStatuses !== undefined &&
                      taskDetailsState.task.allStatuses?.map((s, index) => (
                        <Option key={s.value} value={s.value} label={s.label}>
                          <div className={styles.statusLabel}>
                            <div className={styles.statusIndicator} style={{ backgroundColor: `#${s.color}` }} />
                            {s.label}
                          </div>
                        </Option>
                      ))}
                  </Select>
                </Descriptions.Item>
                <Descriptions.Item label="Priority">
                  <Select defaultValue={taskDetailsState.task.priority?.toString()} style={{ width: 200 }} onChange={handleChangePriority}>
                    {taskDetailsState.task !== undefined &&
                      taskDetailsState.task.allPriorities !== undefined &&
                      taskDetailsState.task.allPriorities?.map((s, index) => (
                        <Option key={s.value} value={s.value} label={s.label}>
                          <div className={styles.statusLabel}>
                            <div className={styles.statusIndicator} style={{ backgroundColor: `#${s.color}` }} />
                            {s.label}
                          </div>
                        </Option>
                      ))}
                  </Select>
                </Descriptions.Item>
                <Descriptions.Item label="Condition">
                  <Select defaultValue={taskDetailsState.task.condition?.toString()} style={{ width: 200 }} onChange={handleChangeCondition}>
                    {taskDetailsState.task !== undefined &&
                      taskDetailsState.task.allConditions !== undefined &&
                      taskDetailsState.task.allConditions?.map((s, index) => (
                        <Option key={s.value} value={s.value} label={s.label}>
                          <div className={styles.statusLabel}>
                            <div className={styles.statusIndicator} style={{ backgroundColor: `#${s.color}` }} />
                            {s.label}
                          </div>
                        </Option>
                      ))}
                  </Select>
                </Descriptions.Item>
                <Descriptions.Item label="Progress">
                  <Row style={{ width: '100%' }}>
                    <Col span={18}>
                      <Slider
                        onChange={handleChangePercentComplete}
                        min={0}
                        max={100}
                        defaultValue={
                          taskDetailsState.task !== undefined && taskDetailsState.task.percentComplete !== undefined
                            ? taskDetailsState.task.percentComplete
                            : 0
                        }
                      />
                    </Col>
                    <Col span={6}>
                      {taskDetailsState.task !== undefined && taskDetailsState.task.percentComplete !== undefined
                        ? taskDetailsState.task.percentComplete
                        : 0}{' '}
                      %
                    </Col>
                  </Row>
                </Descriptions.Item>
                {taskDetailsState.task !== undefined && taskDetailsState.task.project !== undefined && (
                  <Descriptions.Item label="Project">
                    <Link to={`/projects/${taskDetailsState.task.project.id}`}>
                      <ProjectComponent project={taskDetailsState.task.project} />
                    </Link>
                  </Descriptions.Item>
                )}
                <Descriptions.Item label="Planned Completion Date">
                  {taskDetailsState.task.plannedCompletionDate !== undefined &&
                    new Intl.DateTimeFormat('en-US', {
                      month: 'numeric',
                      day: 'numeric',
                      year: 'numeric',
                    }).format(DateTime.fromISO(taskDetailsState.task.plannedCompletionDate))}
                </Descriptions.Item>
                <Descriptions.Item label="Assigned to">
                  {taskDetailsState.task.assignments !== undefined && (
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        {taskDetailsState.task.assignments.map((assignmentInfo, index) => {
                          if (assignmentInfo.assignedTo !== undefined && assignmentInfo.role !== undefined) {
                            return (
                              <div key={assignmentInfo.id} className={styles.assignment}>
                                <div className={styles.assignment_avatar}>
                                  <Tooltip title={`${assignmentInfo.assignedTo.firstName} ${assignmentInfo.assignedTo.lastName}`}>
                                    <div style={{ width: '40px', height: '40px' }}>
                                      <Avatar src={assignmentInfo.assignedTo.avatarDownloadURL} />
                                      {assignmentInfo.status === 'DN' && (
                                        <CheckCircleFilled style={{ color: 'green', position: 'absolute', top: '22px', left: '22px' }} />
                                      )}
                                    </div>
                                  </Tooltip>
                                </div>
                                <div className={styles.assignment_info}>
                                  {`${assignmentInfo.assignedTo.firstName} ${assignmentInfo.assignedTo.lastName}`}
                                  <br />
                                  <div className={styles.assignment_subtitle}>{assignmentInfo.role?.name}</div>
                                </div>
                                <div style={{ clear: 'both' }} />
                              </div>
                            )
                          }
                          if (assignmentInfo.team !== undefined) {
                            return (
                              <div key={assignmentInfo.id} className={styles.assignment}>
                                <div className={styles.assignment_avatar}>
                                  <Tooltip title={assignmentInfo.team.name}>
                                    <div style={{ width: '40px', height: '40px' }}>
                                      <Avatar icon={<TeamOutlined />} />
                                      {assignmentInfo.status === 'DN' && (
                                        <CheckCircleFilled style={{ color: 'green', position: 'absolute', top: '22px', left: '22px' }} />
                                      )}
                                    </div>
                                  </Tooltip>
                                </div>
                                <div className={styles.assignment_info}>
                                  {assignmentInfo.team?.name}
                                  {assignmentInfo.status}
                                </div>
                                <div style={{ clear: 'both' }} />
                              </div>
                            )
                          }
                          if (assignmentInfo.role !== undefined) {
                            return (
                              <div key={assignmentInfo.id} className={styles.assignment}>
                                <div className={styles.assignment_avatar}>
                                  <Tooltip title={assignmentInfo.role.name}>
                                    <div style={{ width: '40px', height: '40px' }}>
                                      <Avatar icon={<ToolOutlined />} />
                                      {assignmentInfo.status === 'DN' && (
                                        <CheckCircleFilled style={{ color: 'green', position: 'absolute', top: '22px', left: '22px' }} />
                                      )}
                                    </div>
                                  </Tooltip>
                                </div>
                                <div className={styles.assignment_info}>
                                  {assignmentInfo.role?.name}
                                  {assignmentInfo.status}
                                </div>
                                <div style={{ clear: 'both' }} />
                              </div>
                            )
                          }
                          return null
                        })}
                      </Col>
                    </Row>
                  )}
                </Descriptions.Item>
                <Descriptions.Item label="Submitted By">
                  <UserComponent user={taskDetailsState.task.submittedBy} />
                </Descriptions.Item>
              </Descriptions>
            </TabPane>
            <TabPane tab="Docs" key="taskDocs">
              {taskDetailsState.task.documents !== undefined &&
                taskDetailsState.task.documents.map((document, index) => {
                  return (
                    <Row key={document.id} className={styles.document}>
                      <Col span={24}>
                        <div>
                          <div style={{ width: '110px', float: 'left' }}>
                            <Tooltip title={document.name}>
                              <div style={{ width: '100px', height: '100px' }}>
                                <img style={{ objectFit: 'cover' }} src={document.previewURL} width={100} height={100} alt={document.name} />
                              </div>
                            </Tooltip>
                          </div>
                          <div style={{ float: 'left' }}>
                            <div>{document.name}</div>
                            <div style={{ marginTop: '7px' }}>
                              <Button icon={<UploadOutlined />} onClick={(e): void => handleOpenComposer(document)}>
                                Upload to Hootsuite
                              </Button>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  )
                })}
            </TabPane>
            <TabPane tab="Updates" key="taskUpdates">
              {taskDetailsState.task.updates !== undefined &&
                taskDetailsState.task.updates.map((update, index) => {
                  return <UpdateComponent key={update.id} update={update} />
                })}
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </PageHeader>
  ) : (
    <Loader />
  )
}

export default {
  name: 'Task',
  routeProps: {
    path: '/tasks/:taskId',
    component: TaskDetailsView,
  },
  navProps: {
    title: 'Task ',
  },
}

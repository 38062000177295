import { TaskDetails } from '@antegma/accallio-cloud-hootsuite-adobe-workfront-api-javacript-sdk'
import { AnyAction } from 'redux'
import actions from './actions'

export type TTaskDetailsState = {
  task: TaskDetails
  loading: boolean
}
const initialState = {
  task: {},
  loading: false,
  assignmentInfos: [],
}
export default function taskDetailsReducer(state: TTaskDetailsState = initialState, action: AnyAction): TTaskDetailsState {
  switch (action.type) {
    case actions.SET_STATE:
      return {
        ...state,
        ...action.payload,
      }
    case actions.GET_STATE:
      return state
    default:
      return state
  }
}

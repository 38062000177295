/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AnyAction } from 'redux'
import { TaskDetails } from '@antegma/accallio-cloud-hootsuite-adobe-workfront-api-javacript-sdk'

const actions = {
  GET_TASKS: 'TASKS/GET_TASKS',
  GET_TASKS_COUNT: 'TASKS/GET_TASKS_COUNT',
  CREATE_TASK: 'TASKS/CREATE_TASK',
  SET_STATE: 'TASKS/SET_STATE',
  LOAD_STATE: 'TASKS/LOAD_STATE',
  GET_STATE: 'TASKS/GET_STATE',
  GET_USER: 'TASKS/GET_USER',
  GET_TEAM_REQUESTS: 'TASKS/GET_TEAM_REQUESTS',
  MARK_DONE_TASK: 'TASK_DETAILS/MARK_DONE_TASK',
  MARK_NOT_DONE_TASK: 'TASK_DETAILS/MARK_NOT_DONE_TASK',
  MARK_DONE_WITH_MY_PART_TASK: 'TASK_DETAILS/MARK_DONE_WITH_MY_PART_TASK',
  WORK_ON_IT_TASK: 'TASK_DETAILS/WORK_ON_IT_TASK',
}
export default actions

export const setLoading = (loading: boolean): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload: {
      loading,
    },
  }
}

export const getTasks = (): AnyAction => {
  return {
    type: actions.GET_TASKS,
    payload: {},
  }
}

export const getTeamRequests = (): AnyAction => {
  return {
    type: actions.GET_TEAM_REQUESTS,
    payload: {},
  }
}

export const getTaskDetailssCount = (ownerId: string): AnyAction => {
  return {
    type: actions.GET_TASKS_COUNT,
    payload: {
      ownerId,
    },
  }
}

export const createTaskDetails = (task: TaskDetails): AnyAction => {
  return {
    type: actions.CREATE_TASK,
    payload: {
      task,
    },
  }
}

export const markDoneTask = (taskId: string, assignmentId: string): AnyAction => {
  return {
    type: actions.MARK_DONE_TASK,
    payload: {
      taskId,
      assignmentId,
    },
  }
}

export const markNotDoneTask = (taskId: string, assignmentId: string): AnyAction => {
  return {
    type: actions.MARK_NOT_DONE_TASK,
    payload: {
      taskId,
      assignmentId,
    },
  }
}

export const markDoneWithMyPartTask = (taskId: string, assignmentId: string): AnyAction => {
  return {
    type: actions.MARK_DONE_WITH_MY_PART_TASK,
    payload: {
      taskId,
      assignmentId,
    },
  }
}

export const workOnItTask = (taskId: string, assignmentId: string): AnyAction => {
  return {
    type: actions.WORK_ON_IT_TASK,
    payload: {
      taskId,
      assignmentId,
    },
  }
}

export const setState = (payload: any): AnyAction => {
  return {
    type: actions.SET_STATE,
    payload,
  }
}

import { UserInfo } from '@antegma/accallio-cloud-hootsuite-adobe-workfront-api-javacript-sdk'
import { Avatar, Tooltip } from 'antd'
import React from 'react'

type UserComponentProps = {
  user?: UserInfo
}

export const UserComponent = (props: UserComponentProps): JSX.Element => {
  const { user } = props

  return (
    <div>
      {user && (
        <Tooltip placement="leftTop" title={`${user.firstName} ${user.lastName}`} arrowPointAtCenter>
          <Avatar src={user.avatarDownloadURL} alt={`${user.firstName} ${user.lastName}`} />
        </Tooltip>
      )}
    </div>
  )
}

export default UserComponent

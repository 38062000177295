import React from 'react'
import { TeamOutlined, ToolOutlined } from '@ant-design/icons'
import { Avatar, Tooltip } from 'antd'
import { AssignmentInfo } from '@antegma/accallio-cloud-hootsuite-adobe-workfront-api-javacript-sdk'
import styles from './styles.module.scss'

type AssignmentInfoComponentProps = {
  assignments?: AssignmentInfo[]
}

export const AssignmentInfoComponent = (props: AssignmentInfoComponentProps): JSX.Element => {
  const { assignments } = props

  let firstAssignments
  let secondAssignments

  if (assignments !== undefined) {
    firstAssignments = assignments.slice(0, 4)
    secondAssignments = assignments.slice(4)
  }

  const getTooltip = (): string => {
    let label = ''
    secondAssignments.map((assignmentInfo, index) => {
      if (label !== '') {
        label += ', '
      }
      if (assignmentInfo.assignedTo !== undefined) {
        label += `${assignmentInfo.assignedTo.firstName} ${assignmentInfo.assignedTo.lastName}`
      } else if (assignmentInfo.team !== undefined) {
        label += assignmentInfo.team.name
      } else if (assignmentInfo.role !== undefined) {
        label += assignmentInfo.role.name
      }
      return true
    })
    return label
  }

  return (
    <div className={styles.assignmentinfo}>
      {firstAssignments !== undefined &&
        firstAssignments.map((assignmentInfo, index) => {
          if (assignmentInfo.assignedTo !== undefined && assignmentInfo.role !== undefined) {
            return (
              <Tooltip
                key={assignmentInfo.id}
                className={styles.assignment}
                title={`${assignmentInfo.assignedTo.firstName} ${assignmentInfo.assignedTo.lastName}`}
                placement="topRight"
              >
                <Avatar className={styles.assignmentIcon} src={assignmentInfo.assignedTo.avatarDownloadURL} size="small" />
              </Tooltip>
            )
          }
          if (assignmentInfo.team !== undefined) {
            return (
              <Tooltip key={assignmentInfo.id} className={styles.assignment} title={assignmentInfo.team.name} placement="topRight">
                <Avatar className={styles.assignmentIcon} icon={<TeamOutlined />} size="small" />
              </Tooltip>
            )
          }
          if (assignmentInfo.role !== undefined) {
            return (
              <Tooltip key={assignmentInfo.id} className={styles.assignment} title={assignmentInfo.role.name} placement="topRight">
                <Avatar className={styles.assignmentIcon} icon={<ToolOutlined />} size="small" />
              </Tooltip>
            )
          }
          return null
        })}
      {secondAssignments !== undefined && secondAssignments.length > 0 && (
        <Tooltip key="additional" className={styles.assignment} title={getTooltip()} placement="topRight">
          <Avatar className={styles.assignmentIcon} size="small">
            +{secondAssignments.length}
          </Avatar>
        </Tooltip>
      )}
    </div>
  )
}

export default AssignmentInfoComponent

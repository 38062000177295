import { TaskApi } from '@antegma/accallio-cloud-hootsuite-adobe-workfront-api-javacript-sdk'
import { Api } from '@antegma/accallio-cloud-react-components/lib/service/api'
import { getEnv } from '@antegma/accallio-cloud-react-components/lib/app/util/env'

type TEnv = {
  ADOBE_WORKFRONT_API_BASE_PATH: string
}

const env = getEnv<TEnv>({
  ADOBE_WORKFRONT_API_BASE_PATH: process.env.REACT_APP_ADOBE_WORKFRONT_API_BASE_PATH || 'https://hootsuite-adobe-workfront-api.apps.accallio.com',
})

const taskApi = new TaskApi(undefined, env.ADOBE_WORKFRONT_API_BASE_PATH, Api.instance.axiosInstance)

export default taskApi

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-named-default */
import React, { useEffect, useState, useContext, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { PageHeader, Button, Row, Col, Divider, Form, Layout, Input, Switch, Select, Radio, DatePicker } from 'antd'
import { ApplicationContext } from '@antegma/accallio-cloud-react-components/lib/app/context'
import Loader from '@antegma/accallio-cloud-react-components/lib/app/components/Loader'
import { setActions } from '@antegma/accallio-cloud-react-components/lib/hootsuite/stream/redux/headeractions/actions'
import { TaskDetails } from '@antegma/accallio-cloud-hootsuite-adobe-workfront-api-javacript-sdk'
import { RadioChangeEvent } from 'antd/lib/radio'
import { TActionDefinition } from '@antegma/accallio-cloud-react-components/lib/hootsuite/stream/redux/headeractions/reducers'
import { default as historyAction } from '~/redux/history/actions'
import { ISagas } from '~/redux/interface'
import {
  changeProjectTaskEnabled,
  loadProjects,
  changeProject,
  createTask,
  changeType,
  loadUsers,
  changeAssignedTo,
  changePlannedCompletionDate,
} from '~/redux/create/actions'

const { Footer } = Layout
const { Search } = Input

const CreateView: React.FunctionComponent = () => {
  const ctx = useContext(ApplicationContext)
  const createState = useSelector((state: ISagas) => state.create)
  const dispatch = useDispatch()
  const { TextArea } = Input
  const { Option } = Select
  const [form] = Form.useForm()

  useEffect(() => {
    dispatch(loadProjects(''))

    const mainAction: TActionDefinition = {
      label: '< Back',
      action: historyAction.GO_BACK,
    }

    const subActions: TActionDefinition[] = []
    dispatch(setActions(mainAction, subActions))
  }, [])

  useEffect(() => {
    form.resetFields()
  }, [form])

  const onChangeIsProjectTask = (value): void => {
    dispatch(changeProjectTaskEnabled(value))
  }

  const onChangeProject = (value): void => {
    dispatch(changeProject(value))
  }

  const onChangeType = (e: RadioChangeEvent): void => {
    dispatch(changeType(e.target.value))
  }

  const onChangeAssignedTo = (value): void => {
    dispatch(changeAssignedTo(value))
  }

  const onChangeDueDate = (date, dateString): void => {
    dispatch(changePlannedCompletionDate(date))
  }

  const onSubmit = (): void => {
    if (createState.task !== undefined) {
      const { task, selectedProjectID, projectTaskEnabled, assignedToID, plannedCompletionDate } = createState
      const newTask: TaskDetails = {
        name: form.getFieldValue('name'),
        description: form.getFieldValue('description'),
      }

      if (projectTaskEnabled && selectedProjectID !== undefined) {
        newTask.project = {
          id: selectedProjectID,
        }
      }

      if (projectTaskEnabled && assignedToID !== undefined) {
        newTask.assignments = [
          {
            id: assignedToID,
          },
        ]
      }

      if (plannedCompletionDate !== undefined) {
        newTask.plannedCompletionDate = plannedCompletionDate.toISOString()
      }

      dispatch(createTask(newTask))
    }
  }

  const onSearchProject = (value): void => {
    dispatch(loadProjects(value))
  }

  const onSearchUser = (value): void => {
    dispatch(loadUsers(value))
  }

  return createState.loading !== true ? (
    <PageHeader title="Create Task/Issue">
      <Form
        form={form}
        name="form"
        layout="horizontal"
        labelCol={{ span: 5 }}
        labelAlign="left"
        wrapperCol={{ span: 19 }}
        initialValues={{ name: createState.messageName, description: createState.messageDescription }}
      >
        <Form.Item label="Type">
          <Radio.Group defaultValue="task" buttonStyle="solid" onChange={onChangeType}>
            <Radio.Button value="task">Task</Radio.Button>
            <Radio.Button value="opTask">Issue</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="name" label="Name" required>
          <Input id="name" name="name" inputMode="text" />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <TextArea id="description" name="description" inputMode="text" rows={4} />
        </Form.Item>
        <Form.Item label={`Is Project ${createState.type === 'task' ? 'Task' : 'Issue'}`}>
          <Switch onChange={onChangeIsProjectTask} />
        </Form.Item>
        {createState.projectTaskEnabled && (
          <Form.Item label="Project" required>
            <Select
              disabled={!createState.projectTaskEnabled}
              onChange={onChangeProject}
              showSearch
              onSearch={onSearchProject}
              showArrow={false}
              filterOption={false}
              defaultActiveFirstOption={false}
            >
              {createState.projects !== undefined &&
                createState.projects.map((project) => (
                  <Option key={project.id} value={project.id} label={project.name}>
                    {project.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        )}
        {createState.projectTaskEnabled && (
          <Form.Item label="Assigned To">
            <Select
              onChange={onChangeAssignedTo}
              showSearch
              onSearch={onSearchUser}
              showArrow={false}
              filterOption={false}
              defaultActiveFirstOption={false}
            >
              {createState.users !== undefined &&
                createState.users.map((user) => (
                  <Option key={user.id} value={user.id} label={`${user.firstName} ${user.lastName}`}>
                    {`${user.firstName} ${user.lastName}`}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item label="Due Date">
          <DatePicker showToday showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" onChange={onChangeDueDate} />
        </Form.Item>
        <Button onClick={onSubmit}>Submit</Button>
      </Form>
    </PageHeader>
  ) : (
    <Loader />
  )
}

export default {
  name: 'Create',
  routeProps: {
    path: '/create',
    component: CreateView,
  },
  navProps: {
    title: 'Create Task/Issue ',
  },
}

import { UpdateInfo } from '@antegma/accallio-cloud-hootsuite-adobe-workfront-api-javacript-sdk'
import { Comment } from 'antd'
import React from 'react'
import { DateTime } from 'luxon'

type UpdateComponentProps = {
  update: UpdateInfo
}

export const UpdateComponent = (props: UpdateComponentProps): JSX.Element => {
  const { update } = props
  let { message } = update

  if (update.enteredByName !== undefined) {
    message = `${update.enteredByName} ${message}`
  }

  if (update.messageArgs !== undefined) {
    update.messageArgs.map((messageArg, messageArgIndex) => {
      if (messageArg.text !== undefined) {
        message = message?.replace(`{${messageArgIndex}}`, messageArg.text)
      }
      return true
    })
  }

  const formatedDate = new Intl.DateTimeFormat('en-US', {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  }).format(DateTime.fromISO(update.entryDate))

  return <Comment content={message} datetime={formatedDate} />
}

export default UpdateComponent

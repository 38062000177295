import { ProjectDetails, ProjectInfo } from '@antegma/accallio-cloud-hootsuite-adobe-workfront-api-javacript-sdk'
import React from 'react'

type ProjectComponentProps = {
  project?: ProjectDetails | ProjectInfo
}

export const ProjectComponent = (props: ProjectComponentProps): JSX.Element => {
  const { project } = props

  return <div>{project && <div>{project.name}</div>}</div>
}

export default ProjectComponent

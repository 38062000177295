/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { AnyAction } from 'redux'

const actions = {
  GO_BACK: 'HISTORY/GO_BACK',
}
export default actions

export const goBack = (): AnyAction => {
  return {
    type: actions.GO_BACK,
    payload: {},
  }
}

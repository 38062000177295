/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable camelcase */
/* eslint-disable import/no-named-default */
/* eslint-disable dot-notation */
import { all, put, takeEvery, select, call, AllEffect } from 'redux-saga/effects'
import { AnyAction } from 'redux'
import { TDefaultSagas } from '@antegma/accallio-cloud-react-components/lib/hootsuite/common/redux/interface'
import { default as initialActions } from '@antegma/accallio-cloud-react-components/lib/hootsuite/common/redux/initial/actions'
import actions, { setLoading } from './actions'
import meApi from '~/services/Me'
import tokenApi from '~/services/Token'
import documentApi from '~/services/Document'

function* LOAD_DATA({ payload }: AnyAction) {
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))

  yield put(setLoading(true))

  const options = { headers: { Authorization: `Bearer ${accessToken}` } }

  const meResponse = yield meApi.getMyUserInfo(options)
  const currentUser = meResponse.data

  yield put({
    type: actions.SET_STATE,
    payload: {
      currentUser,
      loading: false,
    },
  })
}

function* OPEN_COMPOSER({ payload }: AnyAction) {
  // const ctx = useContext(ApplicationContext)
  const { accessToken } = yield select((state: TDefaultSagas) => ({ accessToken: state.oidc.user?.access_token }))

  const { document, hootsuiteUserId } = payload

  const dateTime = Date.now()
  const timestamp = Math.floor(dateTime / 1000)

  const options = { headers: { Authorization: `Bearer ${accessToken}` } }

  const url = document.downloadURL

  if (document !== undefined && document.id !== undefined) {
    if (document.name?.endsWith('png')) {
      const directUpload = false

      console.log(`hootsuite user id for attach file to message: ${hootsuiteUserId}`)
      const input = `${hootsuiteUserId}${timestamp}${url}`

      const tokenResponse = yield tokenApi.generateToken(input, options)
      const token = tokenResponse.data
      const fileNameParts = document.name?.split('.')
      let extension = ''
      if (fileNameParts !== undefined) {
        extension = fileNameParts[fileNameParts.length - 1]
      }

      window['hsp']['attachFileToMessageV2']({
        url,
        name: document.name,
        extension,
        timestamp,
        token,
      })
    } else {
      const uploadResponse = yield documentApi.uploadDocumentToHootsuite(document.id, options)
      const hootsuiteMediaId = uploadResponse.data

      console.log(`hootsuite user id for attach to media: ${hootsuiteUserId}`)
      const input = `${hootsuiteUserId}${timestamp}${hootsuiteMediaId}`

      const tokenResponse = yield tokenApi.generateToken(input, options)
      const token = tokenResponse.data

      window['hsp']['attachFileToMessageV2']({
        mediaId: hootsuiteMediaId,
        timestamp,
        token,
      })
    }
  }
}

export default function* rootSaga() {
  yield all([takeEvery(initialActions.LOAD_DATA, LOAD_DATA), takeEvery(actions.OPEN_COMPOSER, OPEN_COMPOSER)])
}
